import fetch from '@rd-web-markets/shared/dist/services/Fetcher';
import { request } from '@rd-web-markets/shared/dist/services/request';
const claimExternalWorkersService = {
  all(claimId) {
    return fetch("/api/claims/".concat(claimId, "/claim_external_workers"), request.get);
  },
  get(claimExternalWorker) {
    return fetch("/api/claims/".concat(claimExternalWorker.claim_id, "/claim_external_workers/").concat(claimExternalWorker.id), request.get);
  },
  delete(claimExternalWorker) {
    return fetch("/api/claims/".concat(claimExternalWorker.claim_id, "/claim_external_workers/").concat(claimExternalWorker.id), request.delete);
  },
  update(claimExternalWorker) {
    return fetch("/api/claims/".concat(claimExternalWorker.claim_id, "/claim_external_workers/").concat(claimExternalWorker.id), {
      ...request.put,
      body: JSON.stringify({
        claim_external_worker: claimExternalWorker
      })
    });
  },
  create(claimExternalWorker) {
    return fetch("/api/claims/".concat(claimExternalWorker.claim_id, "/claim_external_workers"), {
      ...request.post,
      body: JSON.stringify({
        claim_external_worker: claimExternalWorker
      })
    });
  }
};
export default claimExternalWorkersService;