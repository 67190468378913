import fetch from '@rd-web-markets/shared/dist/services/Fetcher';
import { request } from '@rd-web-markets/shared/dist/services/request';
const projectThemesService = {
  delete(reportId, themeId) {
    return fetch("/api/claim_project_reports/".concat(reportId, "/project_themes/").concat(themeId), request.delete);
  },
  create(reportId, project_theme) {
    return fetch("/api/claim_project_reports/".concat(reportId, "/project_themes"), {
      ...request.post,
      body: JSON.stringify({
        project_theme
      })
    });
  }
};
export default projectThemesService;