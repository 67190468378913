import fetch from '@rd-web-markets/shared/dist/services/Fetcher';
import { request } from '@rd-web-markets/shared/dist/services/request';
const claimConsumablesService = {
  all(claimId) {
    return fetch("/api/claims/".concat(claimId, "/claim_consumables"), request.get);
  },
  get(claimConsumable) {
    return fetch("/api/claims/".concat(claimConsumable.claim_id, "/claim_consumables/").concat(claimConsumable.id), request.get);
  },
  delete(claimConsumable) {
    return fetch("/api/claims/".concat(claimConsumable.claim_id, "/claim_consumables/").concat(claimConsumable.id), request.delete);
  },
  update(claimConsumable) {
    return fetch("/api/claims/".concat(claimConsumable.claim_id, "/claim_consumables/").concat(claimConsumable.id), {
      ...request.put,
      body: JSON.stringify({
        claim_consumable: claimConsumable
      })
    });
  },
  create(claimConsumable) {
    return fetch("/api/claims/".concat(claimConsumable.claim_id, "/claim_consumables"), {
      ...request.post,
      body: JSON.stringify({
        claim_consumable: claimConsumable
      })
    });
  }
};
export default claimConsumablesService;