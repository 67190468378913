const headers = {
  consolidated: {
    regular: [{
      text: 'Business Unit',
      filterableByText: true,
      sortable: true,
      valueFnForSortAndFilter: r => {
        var _r$data$object$busine;
        return ((_r$data$object$busine = r.data.object.business_unit) === null || _r$data$object$busine === void 0 ? void 0 : _r$data$object$busine.name) || '';
      }
    }, {
      text: 'Project Name',
      filterableByText: true,
      sortable: true,
      valueFnForSortAndFilter: r => {
        var _r$data$object$claim_;
        return ((_r$data$object$claim_ = r.data.object.claim_project_report) === null || _r$data$object$claim_ === void 0 ? void 0 : _r$data$object$claim_.project_name) || '';
      }
    }, {
      text: 'Name',
      filterableByText: true,
      sortable: true,
      valueFnForSortAndFilter: r => r.data.object.fragmentable.name
    }, {
      text: 'Description of Activity',
      filterableByText: true,
      sortable: true,
      valueFnForSortAndFilter: r => r.data.object.fragmentable.description_of_activity
    }, {
      width: '125px',
      text: 'Staff Provider connetced?',
      sortable: true,
      valueFnForSortAndFilter: r => r.data.object.fragmentable.connected_party
    }, {
      text: 'Total Cost (£)',
      sortable: true,
      valueFnForSortAndFilter: r => Number(r.data.object.total)
    }, {
      text: 'Capitalised Costs (if any)',
      sortable: true,
      valueFnForSortAndFilter: r => Number(r.data.object.capitalised_costs)
    }, {
      text: 'Direct R&D%',
      sortable: true,
      valueFnForSortAndFilter: r => Number(r.data.object.direct_rnd_percentage)
    }, {
      text: 'Indirect R&D%',
      sortable: true,
      valueFnForSortAndFilter: r => Number(r.data.object.indirect_rnd_percentage)
    }, {
      text: 'Consolidated % of Time Spent',
      sortable: true,
      valueFnForSortAndFilter: r => Number(r.data.object.consolidated_percentage_of_time_spent)
    }, {
      text: 'Eligible R&D %',
      sortable: true,
      valueFnForSortAndFilter: r => Number(r.data.object.eligible_rnd_percentage)
    }, {
      text: 'Allocation',
      sortable: true,
      valueFnForSortAndFilter: r => r.data.object.fragmentable.allocation
    }, {
      text: 'R&D Amount',
      sortable: true,
      valueFnForSortAndFilter: r => Number(r.data.object.rnd_amount)
    }, {
      text: 'P&L R&D Expenditure',
      sortable: true,
      valueFnForSortAndFilter: r => Number(r.data.object.pnl_expenditure)
    }, {
      text: 's1308 Claim',
      sortable: true,
      valueFnForSortAndFilter: r => Number(r.data.object.s1308_claim)
    }],
    realtime: [{
      text: 'Business Unit',
      filterableByText: true,
      sortable: true,
      valueFnForSortAndFilter: r => {
        var _r$data$object$busine2;
        return ((_r$data$object$busine2 = r.data.object.business_unit) === null || _r$data$object$busine2 === void 0 ? void 0 : _r$data$object$busine2.name) || '';
      }
    }, {
      text: 'Project Name',
      filterableByText: true,
      sortable: true,
      valueFnForSortAndFilter: r => {
        var _r$data$object$claim_2;
        return ((_r$data$object$claim_2 = r.data.object.claim_project_report) === null || _r$data$object$claim_2 === void 0 ? void 0 : _r$data$object$claim_2.project_name) || '';
      }
    }, {
      text: 'Period (Month, Qtr, Half)'
    }, {
      text: 'Name',
      filterableByText: true,
      sortable: true,
      valueFnForSortAndFilter: r => r.data.object.fragmentable.name
    }, {
      text: 'Description of Activity',
      filterableByText: true,
      sortable: true,
      valueFnForSortAndFilter: r => r.data.object.fragmentable.description_of_activity
    }, {
      width: '125px',
      text: 'Staff Provider connetced?',
      sortable: true,
      valueFnForSortAndFilter: r => r.data.object.fragmentable.connected_party
    }, {
      text: 'Total Cost (£)',
      sortable: true,
      valueFnForSortAndFilter: r => Number(r.data.object.total)
    }, {
      text: 'Capitalised Costs (if any)',
      sortable: true,
      valueFnForSortAndFilter: r => Number(r.data.object.capitalised_costs)
    }, {
      text: 'Direct R&D%',
      sortable: true,
      valueFnForSortAndFilter: r => Number(r.data.object.direct_rnd_percentage)
    }, {
      text: 'Indirect R&D%',
      sortable: true,
      valueFnForSortAndFilter: r => Number(r.data.object.indirect_rnd_percentage)
    }, {
      text: 'Consolidated % of Time Spent',
      sortable: true,
      valueFnForSortAndFilter: r => Number(r.data.object.consolidated_percentage_of_time_spent)
    }, {
      text: 'Eligible R&D %',
      sortable: true,
      valueFnForSortAndFilter: r => Number(r.data.object.eligible_rnd_percentage)
    }, {
      text: 'Allocation',
      sortable: true,
      valueFnForSortAndFilter: r => r.data.object.fragmentable.allocation
    }, {
      text: 'R&D Amount',
      sortable: true,
      valueFnForSortAndFilter: r => Number(r.data.object.rnd_amount)
    }, {
      text: 'P&L R&D Expenditure',
      sortable: true,
      valueFnForSortAndFilter: r => Number(r.data.object.pnl_expenditure)
    }, {
      text: 's1308 Claim',
      sortable: true,
      valueFnForSortAndFilter: r => Number(r.data.object.s1308_claim)
    }]
  }
};
const buildRows = {
  consolidated: {
    regular: (costFragments, buildCustomCell) => costFragments.map((costFragment, index) => ({
      key: costFragment.id,
      data: {
        object: costFragment
      },
      columns: [buildCustomCell({
        cellType: 'BusinessUnitDropdown',
        costFragment
      }), buildCustomCell({
        cellType: 'ClaimProjectReportDropdown',
        costFragment
      }), buildCustomCell({
        fieldName: 'name',
        costFragment,
        cellType: 'text',
        model: 'fragmentable'
      }), buildCustomCell({
        fieldName: 'description_of_activity',
        costFragment,
        cellType: 'text',
        model: 'fragmentable'
      }), buildCustomCell({
        fieldName: 'connected_party',
        costFragment,
        cellType: 'checkbox',
        model: 'fragmentable'
      }), buildCustomCell({
        fieldName: 'total',
        costFragment,
        cellType: 'number'
      }), buildCustomCell({
        fieldName: 'capitalised_costs',
        costFragment,
        cellType: 'number'
      }), buildCustomCell({
        fieldName: 'direct_rnd_percentage',
        costFragment,
        cellType: 'number'
      }), buildCustomCell({
        fieldName: 'indirect_rnd_percentage',
        costFragment,
        cellType: 'number'
      }), buildCustomCell({
        fieldName: 'consolidated_percentage_of_time_spent',
        costFragment,
        cellType: 'number'
      }), buildCustomCell({
        fieldName: 'eligible_rnd_percentage',
        costFragment,
        cellType: 'number',
        disabled: true
      }), buildCustomCell({
        fieldName: 'allocation',
        costFragment,
        cellType: 'number',
        disabled: true,
        model: 'fragmentable'
      }), buildCustomCell({
        fieldName: 'rnd_amount',
        costFragment,
        cellType: 'number',
        disabled: true
      }), buildCustomCell({
        fieldName: 'pnl_expenditure',
        costFragment,
        cellType: 'number',
        disabled: true
      }), buildCustomCell({
        fieldName: 's1308_claim',
        costFragment,
        cellType: 'number',
        disabled: true
      })]
    })),
    realtime: (costFragments, buildCustomCell) => costFragments.map(costFragment => ({
      key: costFragment.id,
      data: {
        object: costFragment
      },
      columns: [buildCustomCell({
        cellType: 'BusinessUnitDropdown',
        costFragment
      }), buildCustomCell({
        cellType: 'ClaimProjectReportDropdown',
        costFragment
      }), '', buildCustomCell({
        fieldName: 'name',
        costFragment,
        cellType: 'text',
        model: 'fragmentable'
      }), buildCustomCell({
        fieldName: 'description_of_activity',
        costFragment,
        cellType: 'text',
        model: 'fragmentable'
      }), buildCustomCell({
        fieldName: 'connected_party',
        costFragment,
        cellType: 'checkbox',
        model: 'fragmentable'
      }), buildCustomCell({
        fieldName: 'total',
        costFragment,
        cellType: 'number'
      }), buildCustomCell({
        fieldName: 'capitalised_costs',
        costFragment,
        cellType: 'number'
      }), buildCustomCell({
        fieldName: 'direct_rnd_percentage',
        costFragment,
        cellType: 'number'
      }), buildCustomCell({
        fieldName: 'indirect_rnd_percentage',
        costFragment,
        cellType: 'number'
      }), buildCustomCell({
        fieldName: 'consolidated_percentage_of_time_spent',
        costFragment,
        cellType: 'number'
      }), buildCustomCell({
        fieldName: 'eligible_rnd_percentage',
        costFragment,
        cellType: 'number',
        disabled: true
      }), buildCustomCell({
        fieldName: 'allocation',
        costFragment,
        cellType: 'number',
        disabled: true,
        model: 'fragmentable'
      }), buildCustomCell({
        fieldName: 'rnd_amount',
        costFragment,
        cellType: 'number',
        disabled: true
      }), buildCustomCell({
        fieldName: 'pnl_expenditure',
        costFragment,
        cellType: 'number',
        disabled: true
      }), buildCustomCell({
        fieldName: 's1308_claim',
        costFragment,
        cellType: 'number',
        disabled: true
      })]
    }))
  }
};
export const staffingCostFragmentTableSettings = {
  headers,
  buildRows
};