import fetch from '@rd-web-markets/shared/dist/services/Fetcher';
import { request } from '@rd-web-markets/shared/dist/services/request';
const activityNotesService = {
  delete(reportId, noteId) {
    return fetch("/api/claim_project_reports/".concat(reportId, "/project_activity_notes/").concat(noteId), request.delete);
  },
  create(reportId, project_activity_note) {
    return fetch("/api/claim_project_reports/".concat(reportId, "/project_activity_notes"), {
      ...request.post,
      body: JSON.stringify({
        project_activity_note
      })
    });
  },
  edit(reportId, project_activity_note) {
    return fetch("/api/claim_project_reports/".concat(reportId, "/project_activity_notes/").concat(project_activity_note.id), {
      ...request.put,
      body: JSON.stringify({
        project_activity_note
      })
    });
  }
};
export default activityNotesService;