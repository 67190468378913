import { useErrorHandling } from '@rd-web-markets/shared/dist/hooks/useErrorHandling';
import { buildService } from '@rd-web-markets/shared/dist/services/service';
import { useCallback, useEffect, useState } from 'react';
const importTemplateApi = buildService('/companies/{id}/import_competent_professional_templates', {
  only: ['all', 'create', 'base']
}, {
  only: ['postFormData']
});
const competentProfessionalsTemplateService = {
  // Fetches the last import status.
  // In case the last import status is pending, the page itself starts the 
  // fetch import status interval.
  // The loading state will be false, only when the import status is successfully fetched,
  // and is not 'pending'.
  useGetImportStatus: _ref => {
    let {
      companyId,
      setLoading,
      project_id = null
    } = _ref;
    const [importStatus, setImportStatus] = useState(null);
    const [fetchImportStatusInterval, setFetchImportStatusInterval] = useState(null);
    const getImportStatus = useErrorHandling(useCallback(async () => {
      setLoading && setLoading(true);
      const queryParams = project_id ? {
        project_id
      } : {};
      const response = await importTemplateApi.all(companyId, queryParams);
      const newImportStatus = response === null ? {
        import_status: 'never_imported'
      } : {
        ...response
      };
      setImportStatus(newImportStatus);
      if (newImportStatus.import_status !== 'pending') {
        setLoading && setLoading(false);
      }
    }, [companyId, project_id, setLoading]), null, useCallback(() => {
      fetchImportStatusInterval && fetchImportStatusInterval.clear();
    }, [fetchImportStatusInterval]));
    useEffect(() => {
      if (!fetchImportStatusInterval) {
        getImportStatus();
        setFetchImportStatusInterval(importTemplateApi.base.createInterval(getImportStatus, 4000));
      }
    }, [fetchImportStatusInterval, getImportStatus]);
    return [importStatus, setImportStatus, getImportStatus, fetchImportStatusInterval];
  },
  useImportCompetentProfessionalsTemplate: _ref2 => {
    let {
      companyId,
      setImportStatus,
      setLoading,
      project_id = null
    } = _ref2;
    const importCompetentProfessionalsTemplate = useErrorHandling(useCallback(async formData => {
      setLoading && setLoading(true);
      const queryParams = project_id ? {
        project_id
      } : {};
      const response = await importTemplateApi.postFormData(companyId, formData, queryParams);
      setImportStatus({
        ...response
      });
    }, [companyId, project_id, setImportStatus, setLoading]));
    return importCompetentProfessionalsTemplate;
  },
  useExportTemplate: _ref3 => {
    let {
      companyId,
      project_id = null
    } = _ref3;
    return () => {
      if (project_id) {
        window.open("/api/claim_project_reports/".concat(project_id, "/export_competent_professional_template"));
      } else {
        window.open("/api/companies/".concat(companyId, "/export_competent_professional_template"));
      }
    };
  }
};
competentProfessionalsTemplateService.useImport = _ref4 => {
  let {
    companyId,
    setLoading,
    project_id = null
  } = _ref4;
  const [importStatus, setImportStatus, getImportStatus, fetchImportStatusInterval] = competentProfessionalsTemplateService.useGetImportStatus({
    companyId,
    setLoading,
    project_id
  });
  const importCompetentProfessionalsTemplate = competentProfessionalsTemplateService.useImportCompetentProfessionalsTemplate({
    companyId,
    setImportStatus,
    setLoading,
    project_id
  });
  const exportCompetentProfessionalsTemplate = competentProfessionalsTemplateService.useExportTemplate({
    companyId,
    project_id
  });
  return [importStatus, setImportStatus, getImportStatus, fetchImportStatusInterval, importCompetentProfessionalsTemplate, exportCompetentProfessionalsTemplate];
};
export default competentProfessionalsTemplateService;